import { Header } from "./Header";
import { Pane } from "evergreen-ui";

export const Layout = ({ children }: { children: JSX.Element }) => {
  return (
    <Pane clearfix>
      <Header />
      {children}
    </Pane>
  );
};
