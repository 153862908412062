import { Pane, Heading, Button, Menu, Popover, Position } from "evergreen-ui";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();
  return (
    <Pane
      display="flex"
      alignItems="center"
      padding={16}
      background="tint2"
      borderRadius={3}
    >
      <Heading size={600}>true.reports</Heading>
      <Button
        marginLeft="auto"
        marginRight={16}
        onClick={() => navigate("/staff/")}
      >
        Сотрудники
      </Button>
      <Popover
        position={Position.BOTTOM_LEFT}
        content={
          <Menu>
            <Menu.Group>
              <Menu.Item onSelect={() => navigate("/reports/pm/form")}>
                Отчет PM
              </Menu.Item>
              <Menu.Item onSelect={() => navigate("/reports/pm-ts")}>
                Отчет PM-TS
              </Menu.Item>
              <Menu.Item onSelect={() => navigate("/reports/teamlead")}>
                Отчет Teamlead
              </Menu.Item>
            </Menu.Group>
          </Menu>
        }
      >
        <Button marginRight={16}>Отчеты</Button>
      </Popover>
    </Pane>
  );
};
