import { Dialog, Pane, Spinner, Text, TextInput } from "evergreen-ui";
import { Dispatch, SetStateAction, useState } from "react";
import { serverApi } from "../../api/ServerApi";
import { Project } from "../../pages/ReportPm";

export interface ProjectBudgetModalProps {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  project: Project;
  onSubmit: (budget: number) => void;
}

const isNumber = (n: any) => !isNaN(parseFloat(n)) && !isNaN(n - 0);

export const ProjectBudgetModal = (props: ProjectBudgetModalProps) => {
  const [pending, setPending] = useState<boolean>(false);
  const [budget, setBudget] = useState<number>(
    props.project.budget ? props.project.budget : 0
  );

  const handleSubmit = async () => {
    if (!budget) return;
    setPending(true);
    await serverApi.setProjectBudget({
      projectId: props.project.id,
      budget: budget,
    });
    setPending(false);
    props.setIsShown(false);
    props.onSubmit(budget);
  };

  return (
    <Dialog
      isShown={props.isShown}
      title={`Бюджет проекта "${props.project.project_name}"`}
      onConfirm={handleSubmit}
      onCloseComplete={() => props.setIsShown(false)}
      confirmLabel="Сохранить"
      cancelLabel="Отмена"
    >
      <Pane display="flex" alignItems="center" marginBottom={20}>
        <Text marginRight={12}>Введите сумму в рублях</Text>
        {pending && <Spinner size={16} />}
      </Pane>
      <TextInput
        value={budget}
        onChange={(e: any) => {
          if (isNumber(e.target.value) || e.target.value === "") {
            setBudget(e.target.value);
          }
        }}
      />
    </Dialog>
  );
};
