import { Pane, Heading } from "evergreen-ui";
import { Layout } from "./Layout";

export const ServerError = () => {
  return (
    <Layout>
      <Pane padding={36}>
        <Heading size={500} marginBottom={16}>
          Проблема с подключением к серверу.
        </Heading>
      </Pane>
    </Layout>
  );
};
