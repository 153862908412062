import { Heading, Link, Pane } from "evergreen-ui";
import { Layout } from "../components/Layout";

export default function Main() {
  return (
    <Layout>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Heading size={700} marginTop={40}>
          Отчеты true.code
        </Heading>
        <Pane
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          border="default"
          marginTop={24}
          padding={16}
          gap={16}
        >
          <Link href="/reports/pm/form">Отчет "Project Manager"</Link>
          <Link href="/reports/pm-ts/">Отчет "Manager Techsupport"</Link>
          <Link href="/reports/teamlead/">Отчет "Teamlead"</Link>
        </Pane>
      </Pane>
    </Layout>
  );
}
