import { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import { PmReportRequest, serverApi } from "../api/ServerApi";
import { IterationsTable } from "../components/Pm/IterationsTable";
import { StepsTable } from "../components/Pm/StepsTable";
import { Iteration } from "../model/Iteration";
import { Step } from "../model/Step";
import { useSearchParams } from "react-router-dom";
import { Heading, Pane } from "evergreen-ui";
import { ProjectInfo } from "../components/Pm/ProjectInfo";
import { Loading } from "../components/Loading";
import { ServerError } from "../components/ServerError";
import { ProjectInfoPeriod } from "../components/Pm/ProjectInfoPeriod";
import { Time } from "../model/Time";
import { Task } from "../model/Task";
import { StepsTablePeriod } from "../components/Pm/StepsTablePeriod";
import {
  ProjectFinanceTable,
  TimesByEmployee,
} from "../components/Pm/ProjectFinanceTable";
import { Employee } from "./Staff";

export type Project = {
  id: number;
  project_name: string;
  budget: number;
};

export default function ReportPm() {
  const [iterations, setIterations] = useState<Iteration[]>([]);
  const [steps, setSteps] = useState<Step[]>([]);
  const [times, setTimes] = useState<Time[]>([]);
  const [timesByEmployee, setTimesByEmployee] = useState<TimesByEmployee[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [project, setProject] = useState<Project | null>(null);
  const [pending, setPending] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);

  const searchParams = useSearchParams()[0];
  const projectId = Number(searchParams.get("projectId"));
  const dateStart = searchParams.get("dateStart");
  const dateEnd = searchParams.get("dateEnd");

  const isChild = (task: Task, tasks: Task[], step: Step): boolean => {
    if (!task.parent_task_id) {
      return false;
    }
    if (task.parent_task_id === step.task_id) {
      return true;
    }
    const parentTask: Task = tasks.filter(
      (t) => t.id === task.parent_task_id
    )[0];
    return isChild(parentTask, tasks, step);
  };

  const getEmployeesCost = async (params: { times: Time[] }) => {
    const response = await serverApi.getEmployees();
    if (response) {
      const employeesResponse: Employee[] = response;
      const groupedTime: TimesByEmployee[] = [];
      employeesResponse.forEach((employee) => {
        groupedTime.push({
          employee: employee,
          timeValue: 0,
        });
      });
      params.times.forEach((time) => {
        const groupedTimeItem = groupedTime.find(
          (item) => item.employee.id === time.user_id
        );
        if (groupedTimeItem) {
          groupedTimeItem.timeValue = groupedTimeItem.timeValue + time.value;
        }
      });
      setTimesByEmployee(groupedTime.filter((item) => item.timeValue > 0));
    } else {
      setServerError(true);
    }
  };

  const getReport = async (request: PmReportRequest) => {
    const response = await serverApi.getPmReport(request);
    setProject(response.project);
    if (response && dateEnd && dateStart) {
      const iterationsResponse: Iteration[] = response.iterations;
      const stepsResponse: Step[] = response.steps;
      const tasksResponse: Task[] = response.tasks;
      const timeResponse: Time[] = response.times;

      // ПРОСТАВЛЯЕМ ЗАДАЧАМ ИХ НАСТОЯЩЕЕ ВРЕМЯ
      tasksResponse.forEach((task) => {
        task.real_time_fact = 0; // СУММАРНОЕ
        task.real_time_fact_coef = 0; // СУММАРНОЕ С КОЭФИЦИЕНТОМ
        task.real_time_fact_period = 0; // ЗА ПЕРИОД
        task.real_time_fact_coef_period = 0; // ЗА ПЕРИОД С КОЭФИЦИЕНТОМ
        timeResponse.forEach((time) => {
          if (time.task_id === task.id) {
            task.real_time_fact += time.value;
            task.real_time_fact_coef += time.value_with_coefficient;
            if (
              new Date(time.start_date) < new Date(dateEnd) &&
              new Date(time.start_date) > new Date(dateStart)
            ) {
              task.real_time_fact_period += time.value;
              task.real_time_fact_coef_period += time.value_with_coefficient;
            }
          }
        });
      });
      // ПРОСТАВЛЯЕМ ЭТАПАМ ИХ ПОДЗАДАЧИ И НАСТОЯЩЕЕ ВРЕМЯ
      stepsResponse.forEach((step) => {
        const childsArray: Task[] = [];
        tasksResponse.forEach((task) => {
          if (isChild(task, tasksResponse, step)) {
            childsArray.push(task);
          }
        });
        step.real_child_tasks = childsArray;
        // ВРЕМЯ САМОГО ЭТАПА
        step.task_data.real_time_fact = 0; // СУММАРНОЕ
        step.task_data.real_time_fact_coef = 0; // СУММАРНОЕ С КОЭФИЦИЕНТОМ
        step.task_data.real_time_fact_period = 0; // ЗА ПЕРИОД
        step.task_data.real_time_fact_coef_period = 0; // ЗА ПЕРИОД С КОЭФИЦИЕНТОМ
        timeResponse.forEach((time) => {
          if (time.task_id === step.task_id) {
            step.task_data.real_time_fact += time.value;
            step.task_data.real_time_fact_coef += time.value_with_coefficient;
            if (
              new Date(time.start_date) < new Date(dateEnd) &&
              new Date(time.start_date) > new Date(dateStart)
            ) {
              step.task_data.real_time_fact_period += time.value;
              step.task_data.real_time_fact_coef_period +=
                time.value_with_coefficient;
            }
          }
        });
        // СУММАРНОЕ ВРЕМЯ ЭТАПА СО ВСЕМИ ПОДЗАДАЧИМИ
        step.real_time_fact = step.task_data.real_time_fact;
        step.real_time_fact_coef = step.task_data.real_time_fact_coef;
        step.real_time_fact_period = step.task_data.real_time_fact_period;
        step.real_time_fact_coef_period =
          step.task_data.real_time_fact_coef_period;
        childsArray.forEach((task) => {
          step.real_time_fact += task.real_time_fact;
          step.real_time_fact_coef += task.real_time_fact_coef;
          step.real_time_fact_period += task.real_time_fact_period;
          step.real_time_fact_coef_period += task.real_time_fact_coef_period;
        });
      });

      // ПРОСТАВЛЯЕМ ИТЕРАЦИЯМ ИХ НАСТОЯЩЕЕ ВРЕМЯ
      iterationsResponse.forEach((iteration) => {
        iteration.real_time_fact = 0; // СУММАРНОЕ
        iteration.real_time_fact_coef = 0; // СУММАРНОЕ С КОЭФИЦИЕНТОМ
        iteration.real_time_fact_period = 0; // ЗА ПЕРИОД
        iteration.real_time_fact_coef_period = 0; // ЗА ПЕРИОД С КОЭФИЦИЕНТОМ
      });
      iterationsResponse.forEach((iteration) => {
        stepsResponse.forEach((step) => {
          if (step.iteration_id == iteration.task_id) {
            iteration.real_time_fact += step.real_time_fact;
            iteration.real_time_fact_coef += step.real_time_fact_coef;
            iteration.real_time_fact_period += step.real_time_fact_period;
            iteration.real_time_fact_coef_period +=
              step.real_time_fact_coef_period;
          }
        });
      });

      setIterations(iterationsResponse);
      setSteps(stepsResponse);
      setTimes(timeResponse);
      setTasks(tasksResponse);
      await getEmployeesCost({ times: timeResponse });
    } else {
      setServerError(true);
    }
    setPending(false);
  };

  useEffect(() => {
    if (projectId && dateStart && dateEnd) {
      getReport({ projectId, dateStart, dateEnd });
    }
    // eslint-disable-next-line
  }, []);

  if (pending) {
    return <Loading />;
  }

  if (serverError) {
    return <ServerError />;
  }

  return (
    <Layout>
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={16}
      >
        <Heading size={800} marginTop={24} marginBottom={24}>
          {`Отчет "Project Manager" по проекту "${project?.project_name}"`}
        </Heading>
        {project && (
          <Pane
            display="flex"
            justifyContent="flex-start"
            width="100%"
            marginTop={12}
            marginBottom={32}
          >
            <ProjectInfo
              project={project}
              iterations={iterations}
              steps={steps}
              dateStart={dateStart}
              dateEnd={dateEnd}
              times={times}
              timesByEmployee={timesByEmployee}
              tasks={tasks}
            />
            <ProjectFinanceTable timesByEmployee={timesByEmployee} />
          </Pane>
        )}
        <IterationsTable iterations={iterations} />
        <StepsTable
          steps={steps}
          iterations={iterations}
          dateStart={dateStart}
          dateEnd={dateEnd}
        />
        <Heading
          size={600}
          marginTop={12}
        >{`Отчет c ${dateStart} по ${dateEnd}`}</Heading>
        {dateStart && dateEnd && (
          <>
            <ProjectInfoPeriod
              steps={steps}
              iterations={iterations}
              dateStart={dateStart}
              dateEnd={dateEnd}
            />
            <StepsTablePeriod
              steps={steps}
              iterations={iterations}
              dateStart={dateStart}
              dateEnd={dateEnd}
            />
          </>
        )}
      </Pane>
    </Layout>
  );
}
