import { Text, Heading, Pane, Spinner, Table, EditIcon } from "evergreen-ui";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../components/Layout";
import { serverApi } from "../api/ServerApi";
import { EmployeeModal } from "../components/Pm/EmployeeModal";

export interface Employee {
  id: number;
  name: string;
  last_name: string;
  department_id: number;
  hour_rate: number | null;
}

export default function Staff() {
  const [serverError, setServerError] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(true);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null
  );
  const [employeeModal, setEmployeeModal] = useState<boolean>(true);

  const navigate = useNavigate();

  const getEmployees = async () => {
    setPending(true);
    const response = await serverApi.getEmployees();
    if (response) {
      setEmployees(response);
    } else {
      setServerError(true);
    }
    setPending(false);
  };

  useEffect(() => {
    getEmployees();
  }, []);

  if (serverError) {
    return (
      <Layout>
        <Pane padding={36}>
          <Heading size={500} marginBottom={16}>
            Проблема с подключением к серверу.
          </Heading>
        </Pane>
      </Layout>
    );
  }

  if (pending) {
    return (
      <Layout>
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={400}
        >
          <Spinner />
        </Pane>
      </Layout>
    );
  }

  return (
    <Layout>
      <Pane padding={36}>
        <Heading size={500} marginBottom={16}>
          Сотрудники
        </Heading>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell>Сотрудник</Table.TextHeaderCell>
            <Table.TextHeaderCell>Стоимость часа</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {employees.map((employee) => (
              <Table.Row key={employee.id}>
                <Table.TextCell>
                  {employee.name + " " + employee.last_name}
                </Table.TextCell>
                <Table.TextCell>
                  <Pane display="flex">
                    <Text>{employee.hour_rate ? employee.hour_rate : "x"}</Text>
                    <EditIcon
                      marginLeft="auto"
                      marginRight={16}
                      color="muted"
                      cursor="pointer"
                      onClick={() => {
                        setSelectedEmployee(employee);
                        setEmployeeModal(true);
                      }}
                    />
                  </Pane>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {selectedEmployee && (
          <EmployeeModal
            employee={selectedEmployee}
            isShown={employeeModal}
            setIsShown={setEmployeeModal}
            onSubmit={() => getEmployees()}
          />
        )}
      </Pane>
    </Layout>
  );
}
