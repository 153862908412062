import axios from "axios";

// const DEV_ENV = true; // dev
const DEV_ENV = false; // prod

const TEMP_URL = DEV_ENV
  ? "http://localhost:8088"
  : "https://t-report.webtm.ru:8443";

export interface PmReportRequest {
  projectId: number;
  dateStart: string;
  dateEnd: string;
}

class ServerApi {
  public async auth(login: string, password: string) {
    const endpoint = TEMP_URL + "/auth/";
    const request = {
      login: login,
      password: password,
    };

    try {
      const response = await axios.post(endpoint, request);
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async checkToken(token: string) {
    const endpoint = TEMP_URL + "/check-token/";
    const request = {
      token: token,
    };

    try {
      const response = await axios.post(endpoint, request);
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async getProjects() {
    const endpoint = TEMP_URL + "/projects/";

    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async getEmployees() {
    const endpoint = TEMP_URL + "/users/";

    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async getPmReport(request: PmReportRequest) {
    const endpoint = TEMP_URL + "/reports/pm/";

    try {
      const response = await axios.post(endpoint, request);
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async getSubtasks(projectId: string) {
    const endpoint = TEMP_URL + "/subtasks/";
    try {
      const response = await axios.post(endpoint, { projectId: projectId });
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async setProjectBudget(params: { projectId: number; budget: number }) {
    const endpoint = TEMP_URL + "/project/budget/";
    try {
      const response = await axios.post(endpoint, {
        projectId: params.projectId,
        budget: params.budget,
      });
      return response.status;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async setEmployeeHourRate(params: {
    employeeId: number;
    hourRate: number;
  }) {
    const endpoint = TEMP_URL + "/users/hour-rate/";
    try {
      const response = await axios.post(endpoint, {
        employeeId: params.employeeId,
        hourRate: params.hourRate,
      });
      return response.status;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

export const serverApi = new ServerApi();
