import { minutesToTime } from "../../utils/minutesToTime";
import { secondsToTime } from "../../utils/secondsToTime";
import { Iteration } from "../../model/Iteration";
import { getPlanFactPercents } from "../../utils/getPlanFactPercents";
import { Heading, Pane, Table } from "evergreen-ui";
import dateFormat from "dateformat";
import { BASE_TASK_URL } from "../../utils/BASE_TASK_URL";

interface IterationsTableProps {
  iterations: Iteration[];
}

export const IterationsTable = (props: IterationsTableProps) => {
  const iterations = props.iterations.sort((a, b) => {
    return a.sort - b.sort;
  });

  const generalPlan = iterations.reduce((plan, iteration) => {
    return plan + iteration.time_plan;
  }, 0);
  const generalFactIterations = iterations.reduce((fact, iteration) => {
    return fact + iteration.time_fact;
  }, 0);
  const generalFactIterationsCoef = iterations.reduce((fact, iteration) => {
    return fact + iteration.time_fact_with_coeff;
  }, 0);

  return (
    <Pane width="100%" marginBottom={32}>
      <Heading size={600} marginBottom={12}>
        Затраты по итерацииям
      </Heading>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell maxWidth="10%">ID </Table.TextHeaderCell>
          <Table.TextHeaderCell>Итерация </Table.TextHeaderCell>
          <Table.TextHeaderCell maxWidth="20%">
            План / Факт
          </Table.TextHeaderCell>
          <Table.TextHeaderCell maxWidth="20%">
            План / Факт (коэф)
          </Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {iterations.length > 0 &&
            iterations.map((iteration: Iteration) => {
              const sumTimePlan = secondsToTime(iteration.time_plan);
              const sumTimeFact = minutesToTime(iteration.time_fact);
              const sumTimeFactCoef = minutesToTime(
                iteration.time_fact_with_coeff
              );

              const percentPlanFact = getPlanFactPercents(
                iteration.time_plan,
                iteration.time_fact
              );

              const percentPlanFactCoef = getPlanFactPercents(
                iteration.time_plan,
                iteration.time_fact_with_coeff
              );

              const intent =
                percentPlanFactCoef > 100
                  ? "danger"
                  : percentPlanFactCoef > 90
                  ? "warning"
                  : "none";

              return (
                <Table.Row
                  key={iteration.task_id}
                  whiteSpace="normal"
                  intent={intent}
                  fontSize={24}
                >
                  <Table.TextCell maxWidth="10%" fontSize={24}>
                    <a
                      href={BASE_TASK_URL + iteration.task_id + "/"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {iteration.task_id}
                    </a>
                  </Table.TextCell>
                  <Table.TextCell>
                    {iteration.task_data.task_name}
                  </Table.TextCell>
                  <Table.TextCell maxWidth="20%">
                    {sumTimePlan +
                      " / " +
                      sumTimeFact +
                      " / " +
                      percentPlanFact +
                      "%"}
                  </Table.TextCell>
                  <Table.TextCell maxWidth="20%">
                    {sumTimePlan +
                      " / " +
                      sumTimeFactCoef +
                      " / " +
                      percentPlanFactCoef +
                      "%"}
                  </Table.TextCell>
                </Table.Row>
              );
            })}
          <Table.Row intent="success" whiteSpace="normal">
            <Table.TextCell maxWidth="10%">-</Table.TextCell>
            <Table.TextCell>ИТОГО</Table.TextCell>
            <Table.TextCell maxWidth="20%">
              {secondsToTime(generalPlan) +
                " / " +
                minutesToTime(generalFactIterations) +
                " / " +
                getPlanFactPercents(generalPlan, generalFactIterations) +
                "%"}
            </Table.TextCell>
            <Table.TextCell maxWidth="20%">
              {secondsToTime(generalPlan) +
                " / " +
                minutesToTime(generalFactIterationsCoef) +
                " / " +
                getPlanFactPercents(generalPlan, generalFactIterationsCoef) +
                "%"}
            </Table.TextCell>
          </Table.Row>
        </Table.Body>
      </Table>
      {
        // Документы по итерациям
      }
      <Heading size={600} marginBottom={12} marginTop={12}>
        Документы по итерациям
      </Heading>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell maxWidth="10%">ID </Table.TextHeaderCell>
          <Table.TextHeaderCell minWidth="40%">Итерация </Table.TextHeaderCell>
          <Table.TextHeaderCell>Акт</Table.TextHeaderCell>
          <Table.TextHeaderCell>Акт отправлен</Table.TextHeaderCell>
          <Table.TextHeaderCell>Акт подписан</Table.TextHeaderCell>
          <Table.TextHeaderCell>Приложение</Table.TextHeaderCell>
          <Table.TextHeaderCell>Статус</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {iterations.length > 0 &&
            iterations.map((iteration: Iteration) => {
              const actSendDate = dateFormat(
                iteration.act_send_date,
                "yyyy-mm-dd"
              );
              const actSignDate = dateFormat(
                iteration.act_sign_date,
                "yyyy-mm-dd"
              );

              const actStatus =
                iteration.act.toLocaleLowerCase() === "не предусмотрен"
                  ? "Не предусмотрен"
                  : actSignDate !== "1899-11-30"
                  ? "Подписан"
                  : actSendDate !== "1899-11-30"
                  ? "На подписании"
                  : "Не отправлен";
              return (
                <Table.Row
                  key={iteration.task_id}
                  whiteSpace="normal"
                  fontSize={24}
                >
                  <Table.TextCell maxWidth="10%" fontSize={24}>
                    <a
                      href={BASE_TASK_URL + iteration.task_id + "/"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {iteration.task_id}
                    </a>
                  </Table.TextCell>
                  <Table.TextCell minWidth="40%">
                    {iteration.task_data.task_name}
                  </Table.TextCell>
                  <Table.TextCell>{actStatus}</Table.TextCell>
                  <Table.TextCell>
                    {actSendDate === "1899-11-30" ? "-" : actSendDate}
                  </Table.TextCell>
                  <Table.TextCell>
                    {actSignDate === "1899-11-30" ? "-" : actSignDate}
                  </Table.TextCell>
                  <Table.TextCell>
                    {iteration.annex ? iteration.annex : "-"}
                  </Table.TextCell>
                  <Table.TextCell>{iteration.task_data.status}</Table.TextCell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </Pane>
  );
};
