import { useEffect, useState } from "react";
import { serverApi } from "../api/ServerApi";
import { Layout } from "../components/Layout";
import { Task } from "../model/Task";
import { minutesToTime } from "../utils/minutesToTime";
import { secondsToTime } from "../utils/secondsToTime";
import { getPlanFactPercents } from "../utils/getPlanFactPercents";
import { Heading, Link, Pane, Table } from "evergreen-ui";
import { Time } from "../model/Time";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loading } from "../components/Loading";
import { ServerError } from "../components/ServerError";
import { BASE_TASK_URL } from "../utils/BASE_TASK_URL";

export default function Subtasks() {
  const [step, setStep] = useState<Task | null>(null);
  const [subtasks, setSubtasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);

  const navigate = useNavigate();

  const searchParams = useSearchParams()[0];
  const stepId = searchParams.get("stepId");
  const dateStart = searchParams.get("dateStart");
  const dateEnd = searchParams.get("dateEnd");
  const projectId = searchParams.get("projectId");

  const isChild = (task: Task, tasks: Task[], stepId: number): boolean => {
    if (!task.parent_task_id) {
      return false;
    }
    if (task.parent_task_id === stepId) {
      return true;
    }
    const parentTask: Task = tasks.filter(
      (t) => t.id === task.parent_task_id
    )[0];
    return isChild(parentTask, tasks, stepId);
  };

  const getSubtasks = async (projectId: string, stepId: string) => {
    setLoading(true);
    const response = await serverApi.getSubtasks(projectId);
    if (response && dateEnd && dateStart) {
      const tasksResponse: Task[] = response.tasks;
      const timeResponse: Time[] = response.times;

      const stepTask: Task = tasksResponse.filter(
        (task) => task.id === +stepId
      )[0];
      stepTask.real_time_fact = 0; // СУММАРНОЕ
      stepTask.real_time_fact_coef = 0; // СУММАРНОЕ С КОЭФИЦИЕНТОМ
      stepTask.real_time_fact_period = 0; // ЗА ПЕРИОД
      stepTask.real_time_fact_coef_period = 0; // ЗА ПЕРИОД С КОЭФИЦИЕНТОМ
      timeResponse.forEach((time) => {
        if (time.task_id === stepTask.id) {
          stepTask.real_time_fact += time.value;
          stepTask.real_time_fact_coef += time.value_with_coefficient;
          if (
            new Date(time.start_date) < new Date(dateEnd) &&
            new Date(time.start_date) > new Date(dateStart)
          ) {
            stepTask.real_time_fact_period += time.value;
            stepTask.real_time_fact_coef_period += time.value_with_coefficient;
          }
        }
      });

      const childsArray: Task[] = [];

      tasksResponse.forEach((task) => {
        if (isChild(task, tasksResponse, +stepId)) {
          childsArray.push(task);
        }
      });

      childsArray.forEach((task) => {
        task.real_time_fact = 0; // СУММАРНОЕ
        task.real_time_fact_coef = 0; // СУММАРНОЕ С КОЭФИЦИЕНТОМ
        task.real_time_fact_period = 0; // ЗА ПЕРИОД
        task.real_time_fact_coef_period = 0; // ЗА ПЕРИОД С КОЭФИЦИЕНТОМ
        timeResponse.forEach((time) => {
          if (time.task_id === task.id) {
            task.real_time_fact += time.value;
            task.real_time_fact_coef += time.value_with_coefficient;
            if (
              new Date(time.start_date) < new Date(dateEnd) &&
              new Date(time.start_date) > new Date(dateStart)
            ) {
              task.real_time_fact_period += time.value;
              task.real_time_fact_coef_period += time.value_with_coefficient;
            }
          }
        });
      });

      setStep(stepTask);
      setSubtasks(childsArray);
    } else {
      setServerError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (projectId && stepId) {
      getSubtasks(projectId, stepId);
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (serverError) {
    return <ServerError />;
  }

  if (step) {
    const stepPlan = secondsToTime(step.time_plan);
    const stepFact = minutesToTime(step.real_time_fact);
    const stepFactCoef = minutesToTime(step.real_time_fact_coef);
    const stepFactPeriod = minutesToTime(step.real_time_fact_period);
    const stepFactCoefPeriod = minutesToTime(step.real_time_fact_coef_period);

    const stepPercentPlanFact = getPlanFactPercents(
      step.time_plan,
      step.real_time_fact
    );

    const stepPercentPlanFactCoef = getPlanFactPercents(
      step.time_plan,
      step.real_time_fact_coef
    );

    const stepPercentPlanFactPeriod = getPlanFactPercents(
      step.time_plan,
      step.real_time_fact_period
    );

    const stepPercentPlanFactCoefPeriod = getPlanFactPercents(
      step.time_plan,
      step.real_time_fact_coef_period
    );

    const generalPlan = subtasks.reduce((plan, task) => {
      return (plan += task.time_plan);
    }, 0);
    const generalFact = subtasks.reduce((fact, task) => {
      return (fact += task.real_time_fact);
    }, 0);
    const generalFactCoef = subtasks.reduce((fact, task) => {
      return (fact += task.real_time_fact_coef);
    }, 0);
    const generalFactPeriod = subtasks.reduce((fact, task) => {
      return (fact += task.real_time_fact_period);
    }, 0);
    const generalFactCoefPeriod = subtasks.reduce((fact, task) => {
      return (fact += task.real_time_fact_coef_period);
    }, 0);

    const generalPercentPlanFact = getPlanFactPercents(
      generalPlan,
      generalFact
    );

    const generalPercentPlanFactCoef = getPlanFactPercents(
      generalPlan,
      generalFactCoef
    );

    const generalPercentPlanFactPeriod = getPlanFactPercents(
      generalPlan,
      generalFactPeriod
    );

    const generalPercentPlanFactCoefPeriod = getPlanFactPercents(
      generalPlan,
      generalFactCoefPeriod
    );

    return (
      <Layout>
        <Pane width="100%" marginBottom={32} padding={16}>
          <Link cursor="pointer" onClick={() => navigate(-1)}>
            Назад
          </Link>
          <Heading
            size={700}
            marginTop={16}
            marginBottom={16}
            textAlign="center"
          >{`Отчет по этапу "${step.task_name}" за все время`}</Heading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell maxWidth="10%">ID</Table.TextHeaderCell>
              <Table.TextHeaderCell>Задача</Table.TextHeaderCell>
              <Table.TextHeaderCell maxWidth="15%">
                План / Факт
              </Table.TextHeaderCell>
              <Table.TextHeaderCell maxWidth="15%">
                План / Факт (коэф)
              </Table.TextHeaderCell>
              <Table.TextHeaderCell maxWidth="10%">Статус</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              <Table.Row whiteSpace="normal">
                <Table.TextCell maxWidth="10%" fontSize={24}>
                  <a
                    href={BASE_TASK_URL + step.id + "/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {step.id}
                  </a>
                </Table.TextCell>
                <Table.TextCell>
                  <b>Этап: "{step.task_name}"</b>
                </Table.TextCell>
                <Table.TextCell maxWidth="15%">
                  <b>
                    {stepPlan +
                      " / " +
                      stepFact +
                      " / " +
                      stepPercentPlanFact +
                      "%"}
                  </b>
                </Table.TextCell>
                <Table.TextCell maxWidth="15%">
                  <b>
                    {stepPlan +
                      " / " +
                      stepFactCoef +
                      " / " +
                      stepPercentPlanFactCoef +
                      "%"}
                  </b>
                </Table.TextCell>
                <Table.TextCell maxWidth="10%">
                  <b>{step.status}</b>
                </Table.TextCell>
              </Table.Row>
              {subtasks.map((task: Task) => {
                const sumTimePlan = secondsToTime(task.time_plan);
                const sumTimeFact = minutesToTime(task.real_time_fact);
                const sumTimeFactCoef = minutesToTime(task.real_time_fact_coef);

                const percentPlanFact = getPlanFactPercents(
                  task.time_plan,
                  task.real_time_fact
                );

                const percentPlanFactCoef = getPlanFactPercents(
                  task.time_plan,
                  task.real_time_fact_coef
                );

                const intent =
                  percentPlanFactCoef > 100
                    ? "danger"
                    : percentPlanFactCoef > 90
                    ? "warning"
                    : "none";

                return (
                  <Table.Row
                    key={task.id}
                    whiteSpace="normal"
                    intent={intent}
                    fontSize={24}
                  >
                    <Table.TextCell maxWidth="10%" fontSize={24}>
                      <a
                        href={BASE_TASK_URL + task.id + "/"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {task.id}
                      </a>
                    </Table.TextCell>
                    <Table.TextCell>{task.task_name}</Table.TextCell>
                    <Table.TextCell maxWidth="15%">
                      {sumTimePlan +
                        " / " +
                        sumTimeFact +
                        " / " +
                        percentPlanFact +
                        "%"}
                    </Table.TextCell>
                    <Table.TextCell maxWidth="15%">
                      {sumTimePlan +
                        " / " +
                        sumTimeFactCoef +
                        " / " +
                        percentPlanFactCoef +
                        "%"}
                    </Table.TextCell>
                    <Table.TextCell maxWidth="10%">
                      {task.status}
                    </Table.TextCell>
                  </Table.Row>
                );
              })}
              <Table.Row whiteSpace="normal" intent="success">
                <Table.TextCell maxWidth="10%" fontSize={24}>
                  -
                </Table.TextCell>
                <Table.TextCell>ИТОГО</Table.TextCell>
                <Table.TextCell maxWidth="15%">
                  {secondsToTime(generalPlan) +
                    " / " +
                    minutesToTime(generalFact) +
                    " / " +
                    generalPercentPlanFact +
                    "%"}
                </Table.TextCell>
                <Table.TextCell maxWidth="15%">
                  {secondsToTime(generalPlan) +
                    " / " +
                    minutesToTime(generalFactCoef) +
                    " / " +
                    generalPercentPlanFactCoef +
                    "%"}
                </Table.TextCell>
                <Table.TextCell maxWidth="10%">-</Table.TextCell>
              </Table.Row>
            </Table.Body>
          </Table>
          {/* ЗА ПЕРИОД */}
          <Heading
            size={700}
            marginTop={40}
            marginBottom={16}
            textAlign="center"
          >{`Отчет по этапу "${step.task_name}" за период с ${dateStart} по ${dateEnd}`}</Heading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell maxWidth="10%">ID</Table.TextHeaderCell>
              <Table.TextHeaderCell>Задача</Table.TextHeaderCell>
              <Table.TextHeaderCell maxWidth="15%">
                План / Факт
              </Table.TextHeaderCell>
              <Table.TextHeaderCell maxWidth="15%">
                План / Факт (коэф)
              </Table.TextHeaderCell>
              <Table.TextHeaderCell maxWidth="10%">Статус</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              <Table.Row whiteSpace="normal">
                <Table.TextCell maxWidth="10%" fontSize={24}>
                  <a
                    href={BASE_TASK_URL + step.id + "/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {step.id}
                  </a>
                </Table.TextCell>
                <Table.TextCell>
                  <b>Этап: "{step.task_name}"</b>
                </Table.TextCell>
                <Table.TextCell maxWidth="15%">
                  <b>
                    {stepPlan +
                      " / " +
                      stepFactPeriod +
                      " / " +
                      stepPercentPlanFactPeriod +
                      "%"}
                  </b>
                </Table.TextCell>
                <Table.TextCell maxWidth="15%">
                  <b>
                    {stepPlan +
                      " / " +
                      stepFactCoefPeriod +
                      " / " +
                      stepPercentPlanFactCoefPeriod +
                      "%"}
                  </b>
                </Table.TextCell>
                <Table.TextCell maxWidth="10%">
                  <b>{step.status}</b>
                </Table.TextCell>
              </Table.Row>
              {subtasks.map((task: Task) => {
                const sumTimePlan = secondsToTime(task.time_plan);
                const sumTimeFact = minutesToTime(task.real_time_fact_period);
                const sumTimeFactCoef = minutesToTime(
                  task.real_time_fact_coef_period
                );

                const percentPlanFact = getPlanFactPercents(
                  task.time_plan,
                  task.real_time_fact_period
                );

                const percentPlanFactCoef = getPlanFactPercents(
                  task.time_plan,
                  task.real_time_fact_coef_period
                );

                const intent =
                  percentPlanFactCoef > 100
                    ? "danger"
                    : percentPlanFactCoef > 90
                    ? "warning"
                    : "none";

                return (
                  <Table.Row
                    key={task.id}
                    whiteSpace="normal"
                    intent={intent}
                    fontSize={24}
                  >
                    <Table.TextCell maxWidth="10%" fontSize={24}>
                      <a
                        href={BASE_TASK_URL + task.id + "/"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {task.id}
                      </a>
                    </Table.TextCell>
                    <Table.TextCell>{task.task_name}</Table.TextCell>
                    <Table.TextCell maxWidth="15%">
                      {sumTimePlan +
                        " / " +
                        sumTimeFact +
                        " / " +
                        percentPlanFact +
                        "%"}
                    </Table.TextCell>
                    <Table.TextCell maxWidth="15%">
                      {sumTimePlan +
                        " / " +
                        sumTimeFactCoef +
                        " / " +
                        percentPlanFactCoef +
                        "%"}
                    </Table.TextCell>
                    <Table.TextCell maxWidth="10%">
                      {task.status}
                    </Table.TextCell>
                  </Table.Row>
                );
              })}
              <Table.Row whiteSpace="normal" intent="success">
                <Table.TextCell maxWidth="10%" fontSize={24}>
                  -
                </Table.TextCell>
                <Table.TextCell>ИТОГО</Table.TextCell>
                <Table.TextCell maxWidth="15%">
                  {secondsToTime(generalPlan) +
                    " / " +
                    minutesToTime(generalFactPeriod) +
                    " / " +
                    generalPercentPlanFactPeriod +
                    "%"}
                </Table.TextCell>
                <Table.TextCell maxWidth="15%">
                  {secondsToTime(generalPlan) +
                    " / " +
                    minutesToTime(generalFactCoefPeriod) +
                    " / " +
                    generalPercentPlanFactCoefPeriod +
                    "%"}
                </Table.TextCell>
                <Table.TextCell maxWidth="10%">-</Table.TextCell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Pane>
      </Layout>
    );
  }

  return <></>;
}
