import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { serverApi } from "../api/ServerApi";
import { Project } from "./ReportPm";
import { Layout } from "../components/Layout";
import { useNavigate } from "react-router-dom";
import { Button, Heading, Pane, Select, Spinner } from "evergreen-ui";
import dateFormat from "dateformat";

export default function FormPm() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(
    null
  );
  const [dateStart, setDateStart] = useState<Date | null>(new Date());
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());

  const [serverError, setServerError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const getProjects = async () => {
    const response = await serverApi.getProjects();
    if (response) {
      const filteredProjects = response.filter((project: Project) => {
        return project.project_name.startsWith("П:");
      });
      setProjects(filteredProjects);
    } else {
      setServerError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProjects();
  }, []);

  const handleRequest = () => {
    if (selectedProjectId && dateStart && dateEnd) {
      const dateStartFormat = dateFormat(dateStart, "yyyy-mm-dd");
      const dateEndFormat = dateFormat(dateEnd, "yyyy-mm-dd");
      const url = `/reports/pm/report?projectId=${selectedProjectId}&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}`;
      navigate(url);
    } else {
      alert("Заполните поля");
    }
  };

  if (serverError) {
    return (
      <Layout>
        <Pane padding={36}>
          <Heading size={500} marginBottom={16}>
            Проблема с подключением к серверу.
          </Heading>
        </Pane>
      </Layout>
    );
  }

  if (loading) {
    return (
      <Layout>
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={400}
        >
          <Spinner />
        </Pane>
      </Layout>
    );
  }

  return (
    <Layout>
      <Pane padding={36}>
        <Heading size={500} marginBottom={16}>
          Выберите проект и даты для отчета
        </Heading>
        <Select
          width="100%"
          onChange={(e) => setSelectedProjectId(+e.target.value)}
          defaultValue={projects[0].id}
        >
          {projects.map((project: Project, index: number) => {
            return (
              <option key={project.id} value={project.id}>
                {project.project_name}
              </option>
            );
          })}
        </Select>
        <Pane
          display="flex"
          alignItems="center"
          gap={12}
          marginTop={24}
          width="100%"
        >
          <DatePicker
            selected={dateStart}
            onChange={(date) => setDateStart(date)}
          />
          <DatePicker
            selected={dateEnd}
            onChange={(date) => setDateEnd(date)}
          />
          <Button
            marginLeft="auto"
            alignSelf="flex-end"
            onClick={handleRequest}
          >
            Загрузить отчет
          </Button>
        </Pane>
      </Pane>
    </Layout>
  );
}
