import { useEffect, useRef, useState } from "react";
import { AuthContext } from "./AuthContext";
import { serverApi } from "../api/ServerApi";

export const AuthContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [isAuthenticated, setAuth] = useState<boolean>(
    localStorage.getItem("token") !== null
  );
  const tokenChecked = useRef<boolean>(false);

  const checkToken = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setAuth(false);
      return;
    } else {
      const response = await serverApi.checkToken(token);
      if (response.success) {
        setAuth(true);
        tokenChecked.current = true;
      } else {
        setAuth(false);
      }
    }
  };

  useEffect(() => {
    if (tokenChecked.current === false) {
      checkToken();
    }
  });

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
