import { Layout } from "../components/Layout";

export default function ReportPmTs() {
  return (
    <Layout>
      <div className="mt-3">
        <h3 className="text-center">Отчет "TS Manager"</h3>
        <p className="text-center">Раздел в разработке</p>
      </div>
    </Layout>
  );
}
