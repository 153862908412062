import { Button, Pane, TextInputField } from "evergreen-ui";
import { Layout } from "../components/Layout";
import { useContext, useEffect, useState } from "react";
import { serverApi } from "../api/ServerApi";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loginError, setLoginError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const { isAuthenticated, setAuth } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleLogin = async () => {
    const response = await serverApi.auth(login, password);
    if (response) {
      setLoginError(response.loginError);
      setPasswordError(response.passwordError);

      if (response.success) {
        setAuth(true);
        localStorage.setItem("token", response.token);
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        height="70vh"
      >
        <Pane width="300px" display="flex" flexDirection="column">
          <TextInputField
            label="Login"
            required
            placeholder="login"
            isInvalid={loginError}
            onChange={(e: any) => {
              setLoginError(false);
              setLogin(e.target.value);
            }}
          />
          <TextInputField
            id="ids-are-optional"
            label="Password"
            required
            placeholder="password"
            type="password"
            isInvalid={passwordError}
            onChange={(e: any) => {
              setPasswordError(false);
              setPassword(e.target.value);
            }}
          />
          <Button marginLeft="auto" onClick={handleLogin}>
            Enter
          </Button>
        </Pane>
      </Pane>
    </Layout>
  );
}
