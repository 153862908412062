import { Pane, Spinner } from "evergreen-ui";
import { Layout } from "./Layout";

export const Loading = () => {
  return (
    <Layout>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={400}
      >
        <Spinner />
      </Pane>
    </Layout>
  );
};
