import { Route, Routes } from "react-router-dom";
import Main from "../pages/Main";
import ReportPm from "../pages/ReportPm";
import ReportPmTs from "../pages/ReportPmTs";
import ReportTeamlead from "../pages/ReportTeamlead";
import FormPm from "../pages/FormPm";
import Subtasks from "../pages/Subtasks";
import { PrivateRoute } from "./PrivateRoute";
import Login from "../pages/Login";
import Staff from "../pages/Staff";

export const useRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Main />} />
        <Route path="/reports/pm/form" element={<FormPm />} />
        <Route path="/reports/pm/report" element={<ReportPm />} />
        <Route path="/reports/subtasks" element={<Subtasks />} />
        <Route path="/reports/pm-ts" element={<ReportPmTs />} />
        <Route path="/reports/teamlead" element={<ReportTeamlead />} />
        <Route path="/staff" element={<Staff />} />
      </Route>
    </Routes>
  );
};

export default useRoutes;
