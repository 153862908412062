import { Dialog, Pane, Spinner, Text, TextInput } from "evergreen-ui";
import { Dispatch, SetStateAction, useState } from "react";
import { serverApi } from "../../api/ServerApi";
import { Project } from "../../pages/ReportPm";
import { Employee } from "../../pages/Staff";

export interface EmployeeModalProps {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  employee: Employee;
  onSubmit: () => void;
}

const isNumber = (n: any) => !isNaN(parseFloat(n)) && !isNaN(n - 0);

export const EmployeeModal = (props: EmployeeModalProps) => {
  const [pending, setPending] = useState<boolean>(false);
  const [hourRate, setHourRate] = useState<number>(
    props.employee.hour_rate ? props.employee.hour_rate : 0
  );

  const handleSubmit = async () => {
    if (!hourRate) return;
    setPending(true);
    await serverApi.setEmployeeHourRate({
      employeeId: props.employee.id,
      hourRate: hourRate,
    });
    setPending(false);
    props.setIsShown(false);
    props.onSubmit();
  };

  return (
    <Dialog
      isShown={props.isShown}
      title={`Стоимость часа для "${props.employee.name} ${props.employee.last_name}"`}
      onConfirm={handleSubmit}
      onCloseComplete={() => props.setIsShown(false)}
      confirmLabel="Сохранить"
      cancelLabel="Отмена"
    >
      <Pane display="flex" alignItems="center" marginBottom={12}>
        <Text marginRight={12}>Введите сумму в рублях</Text>
        {pending && <Spinner size={16} />}
      </Pane>
      <TextInput
        value={hourRate}
        onChange={(e: any) => {
          if (isNumber(e.target.value) || e.target.value === "") {
            setHourRate(e.target.value);
          }
        }}
      />
    </Dialog>
  );
};
