import { Table } from "evergreen-ui";
import { Employee } from "../../pages/Staff";
import { minutesToTime } from "../../utils/minutesToTime";

export interface ProjectFinanceTableProps {
  timesByEmployee: TimesByEmployee[];
}

export interface TimesByEmployee {
  employee: Employee;
  timeValue: number;
}

export const ProjectFinanceTable = (props: ProjectFinanceTableProps) => {
  let totalCost = 0;
  let totalTime = 0;

  props.timesByEmployee.forEach((item) => {
    const hourRate = item.employee.hour_rate ? item.employee.hour_rate : 0;
    const minuteRate = hourRate / 60;
    const cost = Math.floor(minuteRate * item.timeValue);

    totalTime += item.timeValue;
    totalCost += cost;
  });

  return (
    <Table marginLeft={16} flexGrow={1}>
      <Table.Head>
        <Table.TextHeaderCell>Сотрудник</Table.TextHeaderCell>
        <Table.TextHeaderCell textAlign="center">
          Затраты <br /> в часах
        </Table.TextHeaderCell>
        <Table.TextHeaderCell>Ставка</Table.TextHeaderCell>
        <Table.TextHeaderCell textAlign="center">
          Стоимость <br /> (часы х ставка)
        </Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {props.timesByEmployee.map((item) => {
          const hourRate = item.employee.hour_rate
            ? item.employee.hour_rate
            : 0;
          const minuteRate = hourRate / 60;
          const cost = Math.floor(minuteRate * item.timeValue);
          return (
            <Table.Row key={item.employee.id}>
              <Table.TextCell>
                {item.employee.name + " " + item.employee.last_name}
              </Table.TextCell>
              <Table.TextCell textAlign="center">
                {minutesToTime(item.timeValue)}
              </Table.TextCell>
              <Table.TextCell>{hourRate ? hourRate : "-"}</Table.TextCell>
              <Table.TextCell textAlign="center">
                {cost ? cost : "-"}
              </Table.TextCell>
            </Table.Row>
          );
        })}
        <Table.Row intent="success" whiteSpace="normal">
          <Table.TextCell>ИТОГО</Table.TextCell>
          <Table.TextCell textAlign="center">
            {minutesToTime(totalTime)}
          </Table.TextCell>
          <Table.TextCell>-</Table.TextCell>
          <Table.TextCell textAlign="center">{totalCost}</Table.TextCell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
