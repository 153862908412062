export function minutesToTime(minutes: number) {
  let m = minutes % 60;
  let h = (minutes - m) / 60;

  let HHMM =
    (h < 10 ? "0" : "") +
    h.toString() +
    ":" +
    (m < 10 ? "0" : "") +
    m.toString();
  return HHMM;
}
