import { Pane, Link, Text, Heading, EditIcon } from "evergreen-ui";
import { Iteration } from "../../model/Iteration";
import dateFormat from "dateformat";
import { Step } from "../../model/Step";
import { minutesToTime } from "../../utils/minutesToTime";
import { secondsToTime } from "../../utils/secondsToTime";
import { getPlanFactPercents } from "../../utils/getPlanFactPercents";
import { Time } from "../../model/Time";
import { Task } from "../../model/Task";
import { Project } from "../../pages/ReportPm";
import { useState } from "react";
import { ProjectBudgetModal } from "./ProjectBudgetModal";
import { TimesByEmployee } from "./ProjectFinanceTable";
import { getBudgetCostsPercents } from "../../utils/getBudgetCostsPercents";

export interface ProjectInfoProps {
  project: Project;
  iterations: Iteration[];
  steps: Step[];
  times: Time[];
  tasks: Task[];
  timesByEmployee: TimesByEmployee[];
  dateStart: string | null;
  dateEnd: string | null;
}

export const ProjectInfo = (props: ProjectInfoProps) => {
  const [budgetModal, setBudgetModal] = useState<boolean>(false);
  const [budget, setBudget] = useState<number>(
    props.project.budget ? props.project.budget : 0
  );

  const iterations = props.iterations;
  const steps = props.steps;

  const contract = iterations[0] ? iterations[0].contract : false;

  let actsCount = 0;
  let actsAwaiting = 0;
  let actsSignedCount = 0;

  let planByClosed = 0;
  let factByClosed = 0;
  let factByClosedCoef = 0;

  iterations.forEach((iteration) => {
    if (iteration.act.toLocaleLowerCase() !== "не предусмотрен") {
      actsCount++;
    }
    if (dateFormat(iteration.act_send_date, "yyyy-mm-dd") !== "1899-11-30") {
      actsAwaiting++;
    }
    if (dateFormat(iteration.act_sign_date, "yyyy-mm-dd") !== "1899-11-30") {
      actsSignedCount++;
    }
  });
  steps.forEach((step) => {
    if (step.act.toLocaleLowerCase() !== "не предусмотрен") {
      actsCount++;
    }
    if (dateFormat(step.act_sign_date, "yyyy-mm-dd") !== "1899-11-30") {
      actsSignedCount++;
    }
    if (step.task_data.status === "Завершена") {
      planByClosed += step.time_plan;
      factByClosed += step.real_time_fact;
      factByClosedCoef += step.real_time_fact_coef;
    }
  });

  const generalPlanIterations = iterations.reduce((plan, iteration) => {
    return plan + iteration.time_plan;
  }, 0);

  const generalFactIterations = iterations.reduce((fact, iteration) => {
    return fact + iteration.time_fact;
  }, 0);
  const generalFactIterationsCoef = iterations.reduce((fact, iteration) => {
    return fact + iteration.time_fact_with_coeff;
  }, 0);

  const totalCost: number = props.timesByEmployee.reduce((acc, item) => {
    const hourRate = item.employee.hour_rate ? item.employee.hour_rate : 0;
    return acc + hourRate * Math.floor(item.timeValue / 60);
  }, 0);

  const planFactRatio: number = getPlanFactPercents(
    generalPlanIterations,
    generalFactIterations
  );
  const planFactRatioCoef: number = getPlanFactPercents(
    generalPlanIterations,
    generalFactIterationsCoef
  );
  const budgetCostsRatio: number = getBudgetCostsPercents(budget, totalCost);

  return (
    <Pane display="flex" justifyContent="flex-start" flexShrink={0}>
      <Pane
        padding={16}
        width="fit-content"
        border="default"
        display="flex"
        flexDirection="column"
        gap={8}
      >
        <Heading size={500} textAlign="center" marginBottom={12}>
          Общая информация по проекту
        </Heading>
        <Heading size={400} textAlign="center" marginBottom={6}>
          Документы
        </Heading>
        <Text>
          Договор:{" "}
          {contract ? (
            <Link href={contract} target="_blank">
              {contract}
            </Link>
          ) : (
            "-"
          )}
        </Text>
        <Text>Актов ожидает подписания: {actsAwaiting}</Text>
        <Text>
          Акты (подписано/отправлено): {actsSignedCount + " / " + actsAwaiting}
        </Text>
        <Heading size={400} textAlign="center" marginY={8}>
          Затраты по всем задачам
        </Heading>
        <Text>План: {secondsToTime(generalPlanIterations)}</Text>
        <Text>Факт: {minutesToTime(generalFactIterations)}</Text>
        <Text color={planFactRatio > 100 ? "danger" : "default"}>
          Отношение план/факт: {planFactRatio + "%"}
        </Text>
        <Text>
          Факт (c коэффициентом): {minutesToTime(generalFactIterationsCoef)}
        </Text>
        <Text color={planFactRatioCoef > 100 ? "danger" : "default"}>
          Отношение план/факт(c коэффициентом): {planFactRatioCoef + "%"}
        </Text>
        <Heading size={400} textAlign="center" marginY={8}>
          Затраты по закрытым задачам
        </Heading>
        <Text>План по закрытым: {secondsToTime(planByClosed)}</Text>
        <Text>Факт по закрытым: {minutesToTime(factByClosed)}</Text>
        <Text>
          Факт по закрытым(c коэффициентом): {minutesToTime(factByClosedCoef)}
        </Text>
        <Heading size={400} textAlign="center" marginY={8}>
          Финансовая отчетность
        </Heading>
        <Pane display="flex" alignItems="center">
          <Text>Бюджет проекта: {budget} руб.</Text>
          <EditIcon
            marginLeft={8}
            color="muted"
            cursor="pointer"
            onClick={() => setBudgetModal(true)}
          />
        </Pane>
        <Text>Стоимость проекта: {totalCost} руб.</Text>
        <Text color={budgetCostsRatio > 100 ? "danger" : "default"}>
          Отношение бюджет/стоимость: {budgetCostsRatio + "%"}
        </Text>
      </Pane>
      <ProjectBudgetModal
        isShown={budgetModal}
        setIsShown={setBudgetModal}
        project={props.project}
        onSubmit={(budget: number) => setBudget(budget)}
      />
    </Pane>
  );
};
