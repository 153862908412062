import { Pane, Link, Text, Heading } from "evergreen-ui";
import { Iteration } from "../../model/Iteration";
import dateFormat from "dateformat";
import { Step } from "../../model/Step";
import { minutesToTime } from "../../utils/minutesToTime";
import { secondsToTime } from "../../utils/secondsToTime";
import { getPlanFactPercents } from "../../utils/getPlanFactPercents";
import { Time } from "../../model/Time";

export interface ProjectInfoPeriodProps {
  iterations: Iteration[];
  steps: Step[];
  dateStart: string;
  dateEnd: string;
}

export const ProjectInfoPeriod = (props: ProjectInfoPeriodProps) => {
  const iterations = props.iterations;
  const steps = props.steps;

  let actsSignedCountPeriod = 0;
  let stepsClosedCountPeriod = 0;

  let factPeriod = 0;
  let factCoefPeriod = 0;

  iterations.forEach((iteration) => {
    factPeriod += iteration.real_time_fact_period;
    factCoefPeriod += iteration.real_time_fact_coef_period;

    const timeDate = dateFormat(iteration.start_date, "yyyy-mm-dd");
    if (
      new Date(timeDate) <= new Date(props.dateEnd) &&
      new Date(timeDate) >= new Date(props.dateStart)
    ) {
      actsSignedCountPeriod++;
    }
  });
  steps.forEach((step) => {
    const timeDate = dateFormat(step.start_date, "yyyy-mm-dd");
    const closedDate = dateFormat(step.task_data.closed_date, "yyyy-mm-dd");
    if (
      new Date(timeDate) <= new Date(props.dateEnd) &&
      new Date(timeDate) >= new Date(props.dateStart)
    ) {
      actsSignedCountPeriod++;
    }
    if (
      new Date(closedDate) <= new Date(props.dateEnd) &&
      new Date(closedDate) >= new Date(props.dateStart)
    ) {
      stepsClosedCountPeriod++;
    }
  });

  return (
    <Pane
      display="flex"
      marginTop={12}
      marginBottom={32}
      justifyContent="flex-start"
      width="100%"
    >
      <Pane
        padding={16}
        width="fit-content"
        border="default"
        display="flex"
        flexDirection="column"
        gap={8}
      >
        <Heading size={500} textAlign="center" marginBottom={12}>
          Информация по проекту за период
        </Heading>
        <Text>Актов подписано за период: {actsSignedCountPeriod}</Text>
        <Text>Этапов закрыто за период: {stepsClosedCountPeriod}</Text>
        <Text>Факт за период: {minutesToTime(factPeriod)}</Text>
        <Text>
          Факт за период с коэффициентом: {minutesToTime(factCoefPeriod)}
        </Text>
      </Pane>
    </Pane>
  );
};
