import { minutesToTime } from "../../utils/minutesToTime";
import { secondsToTime } from "../../utils/secondsToTime";
import { Iteration } from "../../model/Iteration";
import { Step } from "../../model/Step";
import { getPlanFactPercents } from "../../utils/getPlanFactPercents";
import { BASE_TASK_URL } from "../../utils/BASE_TASK_URL";
import { Heading, Pane, Table } from "evergreen-ui";
import dateFormat from "dateformat";
import { Task } from "../../model/Task";
import { Time } from "../../model/Time";

interface StepsTablePeriodProps {
  iterations: Iteration[];
  steps: Step[];
  dateStart: string;
  dateEnd: string;
}

export const StepsTablePeriod = (props: StepsTablePeriodProps) => {
  const iterations = props.iterations;
  const steps = props.steps;

  const stepsByIterations = new Map();
  const closedStepsByIterations = new Map();
  iterations.forEach((iteration) => {
    const iterationSteps = steps
      .filter(
        (step) =>
          step.iteration_id === iteration.task_id &&
          step.real_time_fact_period !== 0
      )
      .sort((a, b) => {
        return a.sort - b.sort;
      });
    const closedSteps = steps.filter(
      (step) =>
        step.iteration_id === iteration.task_id &&
        new Date(step.task_data.closed_date) < new Date(props.dateEnd) &&
        new Date(step.task_data.closed_date) > new Date(props.dateStart)
    );
    stepsByIterations.set(iteration.task_data.task_name, iterationSteps);
    closedStepsByIterations.set(iteration.task_data.task_name, closedSteps);
  });

  return (
    <Pane width="100%">
      <Heading size={600} marginBottom={12} marginTop={24}>
        {`Затраты по этапам за период c ${props.dateStart} по ${props.dateEnd}`}
      </Heading>
      {iterations.map((iteration) => {
        const iterationName = iteration.task_data.task_name;
        const iterationSteps: Step[] = stepsByIterations.get(iterationName);
        if (!iterationSteps || iterationSteps.length === 0) {
          return <div key={iteration.task_id}></div>;
        }
        const generalPlan = iterationSteps.reduce((plan, step) => {
          return plan + step.time_plan;
        }, 0);
        const generalFactIterations = iterationSteps.reduce((fact, step) => {
          return fact + step.real_time_fact_period;
        }, 0);
        const generalFactIterationsCoef = iterationSteps.reduce(
          (fact, step) => {
            return fact + step.real_time_fact_coef_period;
          },
          0
        );
        return (
          <Pane key={iteration.task_id} marginBottom={60}>
            <Heading size={500} marginBottom={12}>
              {`Этапы итерации "${iterationName}"`}
            </Heading>
            <Table>
              <Table.Head>
                <Table.TextHeaderCell maxWidth="10%">ID</Table.TextHeaderCell>
                <Table.TextHeaderCell>Этап</Table.TextHeaderCell>
                <Table.TextHeaderCell maxWidth="10%">
                  План итерации
                </Table.TextHeaderCell>
                <Table.TextHeaderCell maxWidth="15%">
                  План / Факт
                </Table.TextHeaderCell>
                <Table.TextHeaderCell maxWidth="15%">
                  План / Факт (коэф)
                </Table.TextHeaderCell>
                <Table.TextHeaderCell maxWidth="10%">
                  Статус
                </Table.TextHeaderCell>
                <Table.TextHeaderCell maxWidth="10%">
                  Подробно
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {iterationSteps.map((step: Step) => {
                  const sumTimePlan = secondsToTime(step.time_plan);
                  const sumTimeFact = minutesToTime(step.real_time_fact_period);
                  const sumTimeFactCoef = minutesToTime(
                    step.real_time_fact_coef_period
                  );

                  const percentPlanFact = getPlanFactPercents(
                    step.time_plan,
                    step.real_time_fact_period
                  );

                  const percentPlanFactCoef = getPlanFactPercents(
                    step.time_plan,
                    step.real_time_fact_coef_period
                  );

                  const intent =
                    percentPlanFactCoef > 100
                      ? "danger"
                      : percentPlanFactCoef > 90
                      ? "warning"
                      : "none";

                  return (
                    <Table.Row
                      key={step.task_id}
                      whiteSpace="normal"
                      intent={intent}
                      fontSize={24}
                    >
                      <Table.TextCell maxWidth="10%" fontSize={24}>
                        <a
                          href={BASE_TASK_URL + step.task_id + "/"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {step.task_id}
                        </a>
                      </Table.TextCell>
                      <Table.TextCell>
                        {step.task_data.task_name}
                      </Table.TextCell>
                      <Table.TextCell maxWidth="10%"></Table.TextCell>
                      <Table.TextCell maxWidth="15%">
                        {sumTimePlan +
                          " / " +
                          sumTimeFact +
                          " / " +
                          percentPlanFact +
                          "%"}
                      </Table.TextCell>
                      <Table.TextCell maxWidth="15%">
                        {sumTimePlan +
                          " / " +
                          sumTimeFactCoef +
                          " / " +
                          percentPlanFactCoef +
                          "%"}
                      </Table.TextCell>
                      <Table.TextCell maxWidth="10%">
                        {step.task_data.status}
                      </Table.TextCell>
                      <Table.TextCell maxWidth="10%">
                        <a
                          href={`/reports/subtasks?stepId=${step.task_id}&dateStart=${props.dateStart}&dateEnd=${props.dateEnd}&projectId=${step.project_id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {"=>"}
                        </a>
                      </Table.TextCell>
                    </Table.Row>
                  );
                })}
                <Table.Row intent="success" whiteSpace="normal">
                  <Table.TextCell maxWidth="10%">-</Table.TextCell>
                  <Table.TextCell>ИТОГО</Table.TextCell>
                  <Table.TextCell maxWidth="10%">
                    {secondsToTime(iteration.time_plan)}
                  </Table.TextCell>
                  <Table.TextCell maxWidth="15%">
                    {secondsToTime(generalPlan) +
                      " / " +
                      minutesToTime(generalFactIterations) +
                      " / " +
                      getPlanFactPercents(generalPlan, generalFactIterations) +
                      "%"}
                  </Table.TextCell>
                  <Table.TextCell maxWidth="15%">
                    {secondsToTime(generalPlan) +
                      " / " +
                      minutesToTime(generalFactIterationsCoef) +
                      " / " +
                      getPlanFactPercents(
                        generalPlan,
                        generalFactIterationsCoef
                      ) +
                      "%"}
                  </Table.TextCell>
                  <Table.TextCell maxWidth="10%">-</Table.TextCell>
                  <Table.TextCell maxWidth="10%">-</Table.TextCell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Pane>
        );
      })}
      {
        // Документы
      }
      {iterations.map((iteration) => {
        const iterationName = iteration.task_data.task_name;
        const iterationSteps: Step[] =
          closedStepsByIterations.get(iterationName);
        if (!iterationSteps || iterationSteps.length === 0) {
          return <div key={iteration.task_id}></div>;
        }

        return (
          <Pane key={iteration.task_id} marginBottom={60}>
            <Heading size={600} marginBottom={12} marginTop={12}>
              {`Документы по этапам итерации "${iterationName}"`}
            </Heading>
            <Table>
              <Table.Head>
                <Table.TextHeaderCell maxWidth="10%">ID </Table.TextHeaderCell>
                <Table.TextHeaderCell minWidth="40%">
                  Итерация{" "}
                </Table.TextHeaderCell>
                <Table.TextHeaderCell>Акт</Table.TextHeaderCell>
                <Table.TextHeaderCell>Акт отправлен</Table.TextHeaderCell>
                <Table.TextHeaderCell>Акт подписан</Table.TextHeaderCell>
                <Table.TextHeaderCell>Приложение</Table.TextHeaderCell>
                <Table.TextHeaderCell>Статус</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {iterationSteps.map((step: Step) => {
                  const actSendDate = dateFormat(
                    step.act_send_date,
                    "yyyy-mm-dd"
                  );
                  const actSignDate = dateFormat(
                    step.act_sign_date,
                    "yyyy-mm-dd"
                  );

                  const actStatus =
                    step.act.toLocaleLowerCase() === "не предусмотрен"
                      ? "Не предусмотрен"
                      : actSignDate !== "1899-11-30"
                      ? "Подписан"
                      : actSendDate !== "1899-11-30"
                      ? "На подписании"
                      : "Не отправлен";
                  return (
                    <Table.Row
                      key={step.task_id}
                      whiteSpace="normal"
                      fontSize={24}
                    >
                      <Table.TextCell maxWidth="10%" fontSize={24}>
                        <a
                          href={BASE_TASK_URL + step.task_id + "/"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {step.task_id}
                        </a>
                      </Table.TextCell>
                      <Table.TextCell minWidth="40%">
                        {step.task_data.task_name}
                      </Table.TextCell>
                      <Table.TextCell>{actStatus}</Table.TextCell>
                      <Table.TextCell>
                        {actSendDate === "1899-11-30" ? "-" : actSendDate}
                      </Table.TextCell>
                      <Table.TextCell>
                        {actSignDate === "1899-11-30" ? "-" : actSignDate}
                      </Table.TextCell>
                      <Table.TextCell>
                        {step.annex ? step.annex : "-"}
                      </Table.TextCell>
                      <Table.TextCell>{step.task_data.status}</Table.TextCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Pane>
        );
      })}
    </Pane>
  );
};
